import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getToken } from '../utils/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../NavBar.css'; // Import the CSS file

const NavBar = () => {
  const [loggedIn, setLoggedIn] = useState(getToken() ? true : false);

  useEffect(() => {
    // Function to check token updates
    const checkTokenChange = () => {
      const currentToken = getToken();
      setLoggedIn(currentToken ? true : false);
    };

    // Set an interval to periodically check for token changes
    const tokenInterval = setInterval(checkTokenChange, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(tokenInterval);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
          <li className="nav-item">

          <img 
          src={`${process.env.PUBLIC_URL}/LubeGPT_Icon.png`}
          alt="Icon" 
          style={{ maxWidth: '36px', maxHeight: '36px', width: 'auto', height: 'auto' }} 
        />
          </li>
            
            <li className="nav-item">
              <NavLink
                className={`nav-link ${loggedIn ? 'd-block' : 'd-none'}`}
                activeClassName="active"
                to="/"
              >
                <b>LubeGPT</b>
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                className={`nav-link ${loggedIn ? 'd-block' : 'd-none'}`}
                activeClassName="active"
                to="/dashboard"
              >
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${loggedIn ? 'd-block' : 'd-none'}`}
                activeClassName="active"
                to="/validateEmail"
              >
                Validate Email
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${loggedIn ? 'd-block' : 'd-none'}`}
                activeClassName="active"
                to="/validateMFA"
              >
                Validate MFA
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${loggedIn ? 'd-block' : 'd-none'}`}
                activeClassName="active"
                to="/logout"
              >
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
