import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setUserSession } from '../utils/common';
import axios from 'axios';
import '../App.css'; // Importing CSS for global styles, adjust if you have a specific CSS file for this component
import useFormInput from '../utils/useFormInput';

const Login = () => {
  const navigate = useNavigate(); 
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const backendurl = process.env.REACT_APP_BACKEND_URL;
  const api = 'login/';

//  useEffect(() => {window.location.reload()}, [])

  // Handle the login action
  const handleLogin = () => {

    setError(null);

    setLoading(true);

    axios.post(`${backendurl}${api}`, { username: username.value, password: password.value })
      .then(response => {
        setLoading(false);
//        console.log("response", response);
        setUserSession(response.data.data.token, username.value);
        navigate('/dashboard');
      })
      .catch(error => {
        setLoading(false);
        if (!error.response || !error.response.status) {
          setError("Error has no response property.")
        } else if (error.response.status === 400 || error.response.status === 500) {
          setError(error.response.data.error.message);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      });
  };

  // Handle redirection to the signup page
  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <div className="login-container">
      <h2>LubeGPT</h2>
      <div className="login-form-group">
        <label>Username</label>
        <input type="text" {...username} autoComplete="username" className="login-input"/>
      </div>
      <div className="login-form-group">
        <label>Password</label>
        <input type="password" {...password} autoComplete="new-password" className="login-input"/>
      </div>
      {error && <div className="login-error-msg">{error}</div>}
      <input
        type="button"
        value={loading ? 'Loading...' : 'Login'}
        onClick={handleLogin}
        disabled={loading}
        className="login-button"
      />
      <div className="login-form-group">
        <input
          type="button"
          value="Sign Up"
          onClick={handleSignUp}
          disabled={loading}
          className="login-button my-2"
        />
      </div>
    </div>
  );
}

export default Login;

