import {React, useState} from 'react';
import '../App.css'; // Ensure CSS styles are imported
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilePdf, faPlus, faMinus, faCaretRight, faFilter } from '@fortawesome/free-solid-svg-icons';


const Source = ({ source }) => {
    return (
        <div className="card mb-3">
            <div className="card-body">
                {source.document_name && <p className="card-text"><strong>Document Name:</strong> {source.document_name}</p>}
                {source.page + 1 && <p className="card-text"><strong>Page:</strong> {source.page + 1}</p>}
                {source.chunk && <p className="card-text"><strong>Chunk:</strong> {source.chunk}</p>}
            </div>
        </div>
    );
};

const Sources = ({ sources }) => {

    const [visible, setVisible] = useState(false);

    return (
        <div className="card">
            <div className="card-header">
                <h5>Sources</h5>
                <FontAwesomeIcon icon={visible ? faMinus : faPlus} onClick={() => setVisible(!visible)} style={{cursor: 'pointer'}} />
            </div>
            <div className={`card-body ${visible ? 'd-block' : 'd-none'}`}>
                {sources.map((source, index) => (
                    <Source key={index} source={source} />
                ))}
            </div>
        </div>
    );
}

export default Sources;