import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import axios from 'axios';

import PrivateRoutes from './utils/PrivateRoutes';
import PublicRoutes from './utils/PublicRoutes';
import { getToken, getUser } from './utils/common';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import NavBar from './pages/NavBar';
import ValidateEmail from './pages/ValidateEmail';
import ValidateMFA from './pages/ValidateMFA';
import Logout from './pages/Logout';

function App() {

  //  const [authLoading, setAuthLoading] = useState(true);

  const token = getToken();
  console.log("token", token);

  const user = getUser();
  console.log("user", user);


  // INSERT TOKEN VERIFICATION
  /*
  useEffect(() => {
      const token = getToken();
      if (!token) {
        return;
      }

      axios.get(`http://localhost:4000/verifyToken?token=${token}`).then(response => {
        setUserSession(response.data.token, response.data.user);
        setAuthLoading(false);
      }).catch(error => {
        removeUserSession();
        setAuthLoading(false);
      });
    }, []);

    if (authLoading && getToken()) {
      return <div className="content">Checking Authentication...</div>
    }
  */
 
  return (
    <BrowserRouter>
      <NavBar/>
      <div className="content">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/home" element={<Home />} />
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/validateEmail" element={<ValidateEmail />} />
            <Route path="/validateMFA" element={<ValidateMFA />} />
            <Route path="/logout" element={<Logout />} />

          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;