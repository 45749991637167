import React from 'react'
import { useNavigate } from 'react-router-dom';
import { removeUserSession } from '../utils/common';

const Logout = props => {

    const navigate = useNavigate();

  // Function to log out the user, clear session, and redirect to login page
  const handleLogout = () => {
    removeUserSession();    
    navigate('/login');
  };



    return <>
    
    <input
        className="logout-button"
        type="button"
        onClick={handleLogout}
        value="Logout" // Logout button
      />

    
    </>;
}

export default Logout;