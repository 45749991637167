import {React, useState} from 'react'

// Custom hook for form input management
const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value); // Update state with input value
    }
    return {
      value,
      onChange: handleChange
    }
  }

  export default useFormInput;