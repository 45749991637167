// Import necessary React and utility functions, including axios for API requests
import React, { useState } from 'react';
import axios from 'axios';
import { getUser, getToken} from '../utils/common';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Ensure CSS styles are imported

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value); // Update state with input value
  }
  return {
    value,
    onChange: handleChange
  }
};

const ConfirmEmail = () => {
  const history = useNavigate();
  const confirmationCode = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = getUser();
  const token = getToken();
  const backendurl = process.env.REACT_APP_BACKEND_URL;

  // Handle the login action
  const handleConfirmation = () => {
    setError(null);
    setLoading(true);
    // API call to backend for authentication
    const api = 'validation/email/confirmation/';
    const request = { 
        username: user, 
        token: token,
        authorization_code: confirmationCode.value 
    };

    console.log("USER", user);
    console.log("REQUEST", request);

    axios.post(`${backendurl}${api}`, request).then(response => {
      setLoading(false);
      console.log("response", response)
      //setUserSession(response.data.data.token, username); // username changed from response.data.user
      //history('/dashboard');
    }).catch(error => {
      setLoading(false);
      console.log("error", error)
      if (!error.hasOwnProperty("response") || !error.response.hasOwnProperty("status")) {
        console.log("Error has no response property.")
      } // Handle different types of errors
      else if (error.response.status === 400 || error.response.status === 500) {
        setError(error.response.data.error.message);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    });
  }

return (
    <div className="login-container">
      <h2>Confirm Email</h2>
      <div className="login-form-group">
        <label>Confirmation code</label>
        <input type="text" {...confirmationCode} className="login-input"/>
      </div>
      {error && <div className="login-error-msg">{error}</div>}
      <input
        type="button"
        value={loading ? 'Loading...' : 'Confirm Email'}
        onClick={handleConfirmation}
        disabled={loading}
        className="login-button"
      />
      <div className="login-form-group">
      </div>


    </div>
  );
};

const RequestValidationCode = () => {
  
  const user = getUser();
  const token = getToken();
  const backendurl = process.env.REACT_APP_BACKEND_URL;

  const [apiOutput, setApiOutput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  var request = {
    "username" : user,
    "token" : token,
  }

  // Async function to submit userInput to an API endpoint and set the apiOutput state with the response

  const requestValidation = async () => {
    try {
      // Post request to your API endpoint with the user's input
      console.log(request)
      const api = 'validation/email/request/';

      const response = await axios.post(`${backendurl}${api}`, request);
      // Update the apiOutput state with data received from your API
      console.log(response);
      //setApiOutput(response.data.output);
    } catch (error) {
      // Log any error during the API request
      console.error('Error fetching API output:', error);
//      setErrorMessage(error);

    }
  };

  // JSX to render the dashboard UI
  return (      
    <div>
      <button className="submit-button" onClick={requestValidation}>Request Validation Code</button> {/* Button to submit input */}
      <p>{apiOutput}</p>
      <p>{errorMessage}</p>

    </div>
  );
};

const ValidateEmail = () => {
  return <>
    <RequestValidationCode />
    <ConfirmEmail />
  </>
};

export default ValidateEmail;