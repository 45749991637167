// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
    background-color: #fff; /* White background for the page */
  }
  
  .navbar {
    background-color: #c7e3fb; /* Light blue shade for the navbar */
  }
  
  .navbar-brand {
    font-weight: bold; /* Make the brand title bold */
    color: #00008b; /* Dark blue color for the brand title */
  }
  
  .navbar-brand img {
    margin-right: 5px; /* Add margin to the right of the image */
  }
  
  .nav-link {
    color: #708090; /* Slate grey color for the links */
  }
  
  .nav-link.active {
    font-weight: bold; /* Make active links bold */
  }
    
  .navbar-nav .nav-item.d-block {
    display: block;
  }
  `, "",{"version":3,"sources":["webpack://./src/NavBar.css"],"names":[],"mappings":";AACA;IACI,sBAAsB,EAAE,kCAAkC;EAC5D;;EAEA;IACE,yBAAyB,EAAE,oCAAoC;EACjE;;EAEA;IACE,iBAAiB,EAAE,8BAA8B;IACjD,cAAc,EAAE,wCAAwC;EAC1D;;EAEA;IACE,iBAAiB,EAAE,yCAAyC;EAC9D;;EAEA;IACE,cAAc,EAAE,mCAAmC;EACrD;;EAEA;IACE,iBAAiB,EAAE,2BAA2B;EAChD;;EAEA;IACE,cAAc;EAChB","sourcesContent":["\nbody {\n    background-color: #fff; /* White background for the page */\n  }\n  \n  .navbar {\n    background-color: #c7e3fb; /* Light blue shade for the navbar */\n  }\n  \n  .navbar-brand {\n    font-weight: bold; /* Make the brand title bold */\n    color: #00008b; /* Dark blue color for the brand title */\n  }\n  \n  .navbar-brand img {\n    margin-right: 5px; /* Add margin to the right of the image */\n  }\n  \n  .nav-link {\n    color: #708090; /* Slate grey color for the links */\n  }\n  \n  .nav-link.active {\n    font-weight: bold; /* Make active links bold */\n  }\n    \n  .navbar-nav .nav-item.d-block {\n    display: block;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
